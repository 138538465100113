@import "styles/variables.scss"; @import "styles/colours.scss"; @import "styles/mixins.scss"; @import "styles/typography.scss";
.container {
  position: absolute;
  z-index: 50;
  top: 3.75rem;
  right: 0.5rem;
  left: auto;
  display: flex;
}

.navbar-collapse {
  left: -0.75rem;
  display: block;
  overflow: hidden;
  flex-direction: column;
  justify-content: center;
  border-radius: 1.5rem;
  background-color: transparent;
  background-color: $white;

  @include screen-size('medium') {
    overflow: visible;
    flex-direction: row;
    justify-content: flex-end;
    padding: 0;
    margin: 0;
    background-color: transparent;
    transform: revert;
  }

  &.is-open {
    box-shadow: 0 0 20px 2px rgb(0 0 0 / 10%);
  }

  &--animated {
    border-radius: 0.5rem;
  }

  li > button {
    margin: 10px 0;

    @include screen-size('medium') {
      margin: 0;
    }
  }
}

.mobile-nav {
  display: grid;
  width: 100%;
  margin: 0;
  grid-template-columns: 1fr 1fr;
  text-align: left;

  > li {
    padding: 0.5rem 0;
  }
}

.nav-link {
  @include text-sm;
  @include text-bold;
  display: flex;
  flex-grow: 1;
  padding: 0.5rem 1.5rem;

  color: $grey-900;
  gap: 0.25rem;

  &:hover,
  &.active {
    color: $primary-pink;
    text-decoration: none;

    svg {
      filter: $primary-pink-filter;
    }
  }
}

.navbar-nav {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  align-items: flex-start;
  padding: 0.75rem 0;
  margin: 0;
  gap: 0.5rem;
  list-style-type: none;
  text-align: left;
}

.dropdown-divider {
  overflow: hidden;
  width: 90%;
  height: 0;
  border-top: 1px solid #e9ecef;
  margin: 0 auto;
}

.sign-out {
  padding-top: 1rem;
}
