// fallback font

@font-face {
  font-family: fallback-font;
  src: local('Arial');
}

@mixin font-primary {
  font-family: Nunito, fallback-font, sans-serif !important;
}

@mixin font-secondary {
  font-family: 'Nunito Sans', fallback-font, sans-serif !important;
}

// TODO: remove this once all components are using new styleguide

@mixin text-semibold {
  font-weight: 600;
}

@mixin text-bold {
  font-weight: 700;
}

@mixin text-normal {
  font-weight: 400;
}

@mixin display-2xl {
  @include font-primary;

  font-size: 3.5rem;
  letter-spacing: -0.07rem;
  line-height: 1;
}

@mixin display-xl {
  @include font-primary;

  font-size: 3rem;
  letter-spacing: -0.06rem;
  line-height: 1.2;
}

@mixin display-lg {
  @include font-primary;

  font-size: 2.5rem;
  letter-spacing: -0.05rem;
  line-height: 1.2;
}

@mixin display-md {
  @include font-primary;

  font-size: 2rem;
  letter-spacing: -0.04rem;
  line-height: 1.2;
}

@mixin display-sm {
  @include font-primary;

  font-size: 1.75rem;
  letter-spacing: -0.035rem;
  line-height: 1.2;
}

@mixin display-xs {
  @include font-primary;

  font-size: 1.5rem;
  letter-spacing: -0.03rem;
  line-height: 1.2;
}

@mixin text-xl {
  @include font-primary;

  font-size: 1.3125rem;
  letter-spacing: -0.02625rem;
  line-height: 1.4;
}

@mixin text-lg {
  @include font-primary;

  font-size: 1.125rem;
  letter-spacing: -0.0225rem;
  line-height: 1.4;
}

@mixin text-md {
  @include font-secondary;

  font-size: 1rem;
  letter-spacing: -0.02rem;
  line-height: 1.4;
}

@mixin text-sm {
  @include font-secondary;

  font-size: 0.875rem;
  letter-spacing: -0.0175rem;
  line-height: 1.4;
}

@mixin text-xs {
  @include font-secondary;

  font-size: 0.75rem;
  letter-spacing: -0.015rem;
  line-height: 1.4;
}

@mixin text-xxs {
  @include font-secondary;

  font-size: 0.625rem;
  letter-spacing: 0.0375rem;
  line-height: 1.6;
}

@mixin text-md-link {
  @include text-md;
  @include text-bold;

  color: $brand;
  text-decoration-line: underline;
}

@mixin text-sm-link {
  @include text-sm;
  @include text-bold;

  color: $brand;
  text-decoration-line: underline;
}

@mixin sheet-title {
  font-size: 2rem;
  letter-spacing: -0.04rem;
  line-height: 2.25rem;
}

@mixin header {
  font-size: 2rem;
  letter-spacing: -0.04rem;
  line-height: 2.25rem;
}

@mixin section-header {
  font-size: 1.4375rem;
  letter-spacing: -0.02875rem;
  line-height: 1.75rem;
}

@mixin body-xl {
  font-size: 1.125rem;
  letter-spacing: -0.0225rem;
  line-height: 1.625rem;
}

@mixin body-lg {
  font-size: 1rem;
  letter-spacing: -0.02rem;
  line-height: 1.4375rem;
}

@mixin body-md {
  font-size: 0.875rem;
  letter-spacing: -0.0175rem;
  line-height: 1.0625rem;
}

@mixin body-sm {
  font-size: 0.8125rem;
  letter-spacing: -0.01625rem;
  line-height: 1rem;
}

@mixin body-xs {
  font-size: 0.75rem;
  letter-spacing: -0.015rem;
  line-height: 0.875rem;
}

@mixin micro {
  font-size: 0.625rem;
  letter-spacing: -0.0125rem;
  line-height: 1rem;
}
