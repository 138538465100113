@mixin button-loading-animation {
  display: inline-block;
  height: 25px;
  position: relative;
  width: 64px;

  div {
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
    background: #fff;
    border-radius: 50%;
    height: 11px;
    position: absolute;
    top: 5px;
    width: 11px;

    &:nth-child(1) {
      animation: lds-ellipsis1 0.6s infinite;
      left: 6px;
    }

    &:nth-child(2) {
      animation: lds-ellipsis2 0.6s infinite;
      left: 6px;
    }

    &:nth-child(3) {
      animation: lds-ellipsis2 0.6s infinite;
      left: 26px;
    }

    &:nth-child(4) {
      animation: lds-ellipsis3 0.6s infinite;
      left: 45px;
    }
  }

  @keyframes lds-ellipsis1 {
    0% {
      transform: scale(0);
    }

    100% {
      transform: scale(1);
    }
  }

  @keyframes lds-ellipsis3 {
    0% {
      transform: scale(1);
    }

    100% {
      transform: scale(0);
    }
  }

  @keyframes lds-ellipsis2 {
    0% {
      transform: translate(0, 0);
    }

    100% {
      transform: translate(19px, 0);
    }
  }
}

@mixin search-filter-loader {
  align-items: center;
  display: flex;
  justify-content: center;

  div {
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border: 2px solid $grey-900;
    border-color: $grey-900 transparent transparent transparent;
    border-radius: 50%;
    box-sizing: border-box;
    display: block;
    height: 15px;
    margin: 2px;
    position: absolute;
    width: 15px;

    &:nth-child(1) {
      animation-delay: -0.45s;
    }

    &:nth-child(2) {
      animation-delay: -0.3s;
    }

    &:nth-child(3) {
      animation-delay: -0.15s;
    }
  }

  @keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }
}

@mixin orange-radial-gradient-background {
  background: rgb(253 203 38);
  background: radial-gradient(circle, rgb(253 203 38 / 100%) 10%, rgb(248 174 0 / 100%) 75%);
}

@mixin brand-radial-gradient-background {
  background: rgb(234 80 104);
  background: radial-gradient(circle, rgb(238 110 130 / 100%) 10%, rgb(234 80 104 / 100%) 75%);
}

@mixin brand-gradient-background {
  background: $primary-500;
  background: linear-gradient(270deg, $primary-500 0%, $secondary-500 100%);
}

@mixin loading-animation {
  position: absolute;
  z-index: 1;
  inset: 0 0 0 50%;
  width: 500%;
  margin-left: -250%;
  animation: phAnimation $ph-animation-duration linear infinite;
  background: linear-gradient(
      to right,
      rgba($ph-bg, 0) 46%,
      rgba($ph-bg, 0.35) 50%,
      rgba($ph-bg, 0) 54%
    )
    50% 50%;
  content: ' ';
}

$breakpoint-extra-small: 576px;
$breakpoint-small: 768px;
$breakpoint-medium: 992px;
$breakpoint-large: 1024px;
$breakpoint-extra-large: 1200px;
$breakpoint-content-web: 1352px;
$breakpoint-content-display: 1460px;
$breakpoint-desktop-height: 930px;

/* stylelint-disable */
@mixin screen-size($size, $pixels: $breakpoint-small) {
  @if $size == 'extra-small' {
    @media screen and (min-width: $breakpoint-extra-small) {
      @content;
    }
  } @else if $size == 'small' {
    @media screen and (min-width: $breakpoint-small) {
      @content;
    }
  } @else if $size == 'medium' {
    @media screen and (min-width: $breakpoint-medium) {
      @content;
    }
  } @else if $size == 'large' {
    @media screen and (min-width: $breakpoint-large) {
      @content;
    }
  } @else if $size == 'extra-large' {
    @media screen and (min-width: $breakpoint-extra-large) {
      @content;
    }
  } @else if $size == 'content-web' {
    @media screen and (min-width: $breakpoint-content-web) {
      @content;
    }
  } @else if $size == 'content-display' {
    @media screen and (min-width: $breakpoint-content-display) {
      @content;
    }
  } @else if $size == 'iphone' {
    @media screen and (min-width: 375px) and (max-width: 767px) and (-webkit-max-device-pixel-ratio: 2) {
      @content;
    }
  } @else if $size == 'iphone-14' {
    @media only screen and (device-width: 390px) and (device-height: 844px) and (-webkit-device-pixel-ratio: 3) {
      @content;
    }
  } @else if $size == 'iphone-14-pro' {
    @media only screen and (width: 393px) and (device-height: 852px) and (-webkit-device-pixel-ratio: 3) {
      @content;
    }
  } @else if $size == 'ipad' {
    @media only screen and (min-width: 768px) and (max-width: 1024px) and (-webkit-min-device-pixel-ratio: 2) {
      @content;
    }
  } @else if $size == 'custom' {
    @media screen and (min-width: $pixels) {
      @content;
    }
  } @else {
    @content;
  }
}

/* stylelint-enable */

@mixin screen-height($size, $pixels: $breakpoint-small) {
  @if $size == 'extra-small' {
    @media screen and (min-height: $breakpoint-extra-small) {
      @content;
    }
  }

 @else if $size == 'small' {
    @media screen and (min-height: $breakpoint-small) {
      @content;
    }
  }

 @else if $size == 'medium' {
    @media screen and (min-height: $breakpoint-medium) {
      @content;
    }
  }

 @else if $size == 'large' {
    @media screen and (min-height: $breakpoint-large) {
      @content;
    }
  }

 @else if $size == 'extra-large' {
    @media screen and (min-height: $breakpoint-extra-large) {
      @content;
    }
  }

 @else if $size == 'desktop-height' {
    @media screen and (min-height: $breakpoint-desktop-height) {
      @content;
    }
  }

 @else if $size == 'content-web' {
    @media screen and (min-height: $breakpoint-content-web) {
      @content;
    }
  }

 @else if $size == 'custom' {
    @media screen and (min-height: $pixels) {
      @content;
    }
  }

 @else {
    @content;
  }
}
