@import "styles/variables.scss"; @import "styles/colours.scss"; @import "styles/mixins.scss"; @import "styles/typography.scss";
.main {
  position: relative;
  display: flex;
  width: 100%;
  flex-direction: column;
  cursor: pointer;

  &[data-disabled='true'] {
    .select {
      background-color: $fill-disabled-light;
      color: $label-disabled-light;
      cursor: default;
    }
    .label,
    .foot {
      color: $label-disabled-light;
    }
  }

  &[data-error='true'] {
    .select {
      outline: 1px solid $red;
    }
    .foot {
      color: $red;
    }
  }
}

.label,
.select,
.foot {
  transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
}

.label {
  @include text-sm;
  @include text-bold;
  padding: 0 0 $spacing-xxs;
  margin: 0;
  color: $label-supporting-light;

  .compact & {
    position: relative;
    padding: 0;
    margin-bottom: -$spacing-sm;
    cursor: pointer;
    white-space: nowrap;
  }
}

.select {
  position: relative;
  display: flex;
  overflow: hidden;
  width: 100%;
  border-radius: 0.5rem;
  background-color: $fill-inactive-light;
  cursor: pointer;

  &:hover {
    background-color: $fill-supporting-light;
  }

  &:focus-within,
  &:focus-visible,
  &:target {
    background-color: $fill-supporting-light;

    .control {
      color: $label-primary-light;
    }
  }

  .compact & {
    background-color: transparent;

    &:focus-within,
    &:focus-visible,
    &:target,
    &:hover {
      background: transparent;
    }
  }

  .control {
    @include text-md;
    @include text-normal;
    all: initial;
    width: 100%;
    flex: 1;
    padding: 1rem 3rem 1rem 1.25rem;
    color: $label-primary-light;
    cursor: pointer;
    user-select: none;
    white-space: nowrap !important;

    .compact & {
      padding: 1rem 0 0;
    }

    &.default-selected {
      color: $label-supporting-light;
    }

    option {
      @include text-md;
      @include text-normal;
      all: initial;
    }

    .main[data-disabled='false'] & {
      color: $label-supporting-light;
    }

    &:focus,
    &:focus-within,
    &:focus-visible,
    &:target {
      width: 100%;
      padding: 1rem 3rem 1rem 1.25rem;
      border: 0 none;
      outline: none;

      .compact & {
        padding: 1rem 0 0;
        background: 0 none;
      }
    }
  }

  .icon {
    position: absolute;
    top: 50%;
    right: 1.25rem;
    pointer-events: none;
    transform: translateY(-50%);
  }
}

.foot {
  @include text-sm;
  @include text-normal;
  position: relative;
  z-index: 5;
  display: flex;
  align-items: center;
  padding: 0.25rem 0 0.5rem;
  color: $label-inactive-light;

  &:hover {
    cursor: default;
  }

  &[data-error='true'] {
    color: $error;
  }
}
