$base-font-size: 16px;

$spacing-none: 0;
$spacing-xxs: 0.25rem;
$spacing-xs: 0.5rem;
$spacing-sm: 1rem;
$spacing-md: 1.5rem;
$spacing-lg: 2rem;
$spacing-xl: 4rem;
$spacing-xxl: 8rem;

$foreground-animation-duration: 600ms;

$account-wrapper-width: 85rem;
$old-blog-wrapper-width: 43.75rem;
$blog-wrapper-width: 43.75rem;
$wrapper-width: 82.5rem;
$small-wrapper-width: 40rem;

$ph-bg: #fff !default;
$ph-color: #d5d5d5 !default;
$ph-border-radius: 0.25rem !default;

$ph-animation-duration: 0.4s !default;

@keyframes phAnimation {
  0% {
    transform: translate3d(-30%, 0, 0);
  }

  100% {
    transform: translate3d(30%, 0, 0);
  }
}
