@import "styles/variables.scss"; @import "styles/colours.scss"; @import "styles/mixins.scss"; @import "styles/typography.scss";
.container {
  display: flex;
  flex-direction: column;

  @include screen-size('medium') {
    padding: 0 $spacing-md;
  }

  @include screen-size('custom', 1260px) {
    padding: 0 $spacing-lg;
  }

  &[data-background-color='transparent'] {
    border-color: transparent;
    background-color: transparent;
  }

  &[data-background-color='grey'] {
    background-color: $white;

    @include screen-size('medium') {
      background-color: $grey-100;
    }
  }

  &[data-background-color='backdrop'] {
    background-color: $background-backdrop;
  }

  &[data-background-color='white'] {
    background-color: $white;
  }

  &[data-width='fixed'] {
    .top,
    .bottom {
      width: 100%;
      max-width: 94.5rem;
      margin: 0 auto;
    }
  }

  &[data-width='content'] {
    .top,
    .bottom {
      width: 100%;
      margin: 0 auto;

      @include screen-size('large') {
        width: calc(100dvw - 10rem);
        max-width: 1460px;
      }
    }
  }

  &[data-width='browse'] {
    .top,
    .bottom {
      width: 100%;
      margin: 0 auto;

      @include screen-size('custom', 1181px) {
        width: calc(100dvw - 10rem);
        max-width: 1460px;
      }
    }
  }

  &[data-has-border-bottom='always'] {
    border-bottom: 0.0625rem solid $fill-inactive-light;
  }

  &[data-has-border-bottom='mobile'] {
    border-bottom: 0.0625rem solid $fill-inactive-light;
  }

  @include screen-size('large') {
    &[data-has-border-bottom='desktop'] {
      border-bottom: 0.0625rem solid $fill-inactive-light;
    }

    &[data-has-border-bottom='mobile'] {
      border-bottom: 0 none;
    }
  }
}

.top {
  position: relative;
  z-index: 1000;
  display: flex;
  height: 3.5rem;
  align-items: center;
  justify-content: space-between;
  padding: $spacing-xxs $spacing-sm;

  @include screen-size('medium') {
    height: 4rem;
    padding: $spacing-xs 0;
  }
}

.logo {
  display: flex;
  align-items: center;

  // transform: translateY(-4px); // halloween only
  // width: fit-content; // halloween only
  // height: 2.25rem; // halloween only

  > span {
    width: 143px !important;

    // height: 42px !important; // halloween-only
    height: 42px !important; // halloween-only
  }
}

.bottom {
  display: flex;
  overflow: scroll;
  width: 100vw;
  width: 100dvw;
  align-items: center;
  justify-content: space-between;
  padding: $spacing-xxs $spacing-sm $spacing-sm;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  @include screen-size('medium') {
    padding: $spacing-xxs 0 $spacing-sm;
    margin: 0;
  }

  @include screen-size('custom', 1260px) {
    display: none;
  }
}

.right,
.left {
  z-index: 10;
  display: flex;
  width: fit-content;
  align-items: center;
  gap: $spacing-xs;

  &.is-sticky {
    position: fixed;
    top: 0.5rem;
  }
}

.right {
  @include screen-size('medium') {
    position: absolute;
    top: 0.75rem;
    right: 0;
  }
}

.nav {
  ul {
    display: flex;
    padding-left: 0;
    margin: 0;
    gap: $spacing-xs;
    list-style-type: none;
  }

  @include screen-size('medium') {
    margin-left: 1rem;
  }

  @include screen-size('custom', 1260px) {
    margin-left: 2rem;
  }
}

.center {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  display: none;
  place-items: center;
  place-self: center;

  @include screen-size('medium') {
    display: grid;
  }
}

.loco-day-button {
  @include text-sm;
  @include text-bold;

  position: relative;
  display: flex;
  padding: 0.4375rem 0.75rem;
  border-radius: 6.25rem;
  background-color: transparent;
  color: $white;
  gap: 0.25rem;
  place-items: center;
  scale: 1;
  transition: all 0.2s ease-in-out;
  white-space: nowrap;

  &.light {
    color: $label-text;
  }

  &:active {
    scale: 0.9;
  }

  &::before {
    position: absolute;
    z-index: 0;
    padding: 0.0625rem;
    border-radius: 100px;
    background: linear-gradient(135deg, #ffd700 0.31%, #ff2d55 99.68%);
    content: '';
    inset: 0;
    mask:
      linear-gradient(#fff 0 0) content-box,
      linear-gradient(#fff 0 0);
    mask-composite: xor;
    mask-composite: exclude;
  }

  &:hover {
    background-color: rgb(120 120 128 / 8%);

    &.light {
      color: $label-text;
    }
  }

  &.active {
    border-color: transparent;
    background: linear-gradient(135deg, #ffd700 0.31%, #ff2d55 99.68%);
    color: $white;

    &:hover,
    &:focus,
    &:active {
      color: $white;
      text-decoration: none;
    }
  }
}
