@import "styles/variables.scss"; @import "styles/colours.scss"; @import "styles/mixins.scss"; @import "styles/typography.scss";
.main {
  @include text-sm;
  @include text-bold;

  @media (hover) and (pointer: fine) {
    &:hover {
      text-decoration: none;
    }

    &:active:not(:disabled, [data-has-transition='false']) {
      scale: 0.9;
    }
  }

  @media (hover) and (pointer: fine) {
    &:disabled:hover {
      background-color: $fill-disabled-light;
      color: $label-disabled-light;
    }
  }

  position: relative;
  z-index: 5;
  display: inline-flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
  scale: 1;
  transition: all 0.2s ease-in-out;
  white-space: nowrap;

  &:visited {
    text-decoration: none;
  }

  &[data-has-underline='true'] {
    text-decoration: underline;
  }

  &[data-has-padding='true'] {
    &[data-is-square='true'] {
      &[data-has-background='true'] {
        border-radius: 50%;
        aspect-ratio: 1 / 1;
      }
    }
  }

  &[data-theme='dark'] {
    &[data-hierarchy='bezeled'],
    &[data-hierarchy='bezeled-gray'],
    &[data-hierarchy='chip-choice'],
    &[data-hierarchy='chip-filter'] {
      background-color: $fill-inactive-light;
      color: $button-anchor-dark;
    }

    &[data-hierarchy='borderless'] {
      color: $button-anchor-dark;
    }

    &[data-hierarchy='borderless-gray'] {
      color: $label-primary-dark;
    }

    &[data-hierarchy='text'] {
      color: $label-primary-dark;

      &:hover:not(:disabled) {
        color: $label-primary-dark;
      }
    }
  }

  &[data-hierarchy='black'] {
    @media (hover) and (pointer: fine) {
      &:hover {
        background-color: $fill-supporting-dark;
      }
    }

    background-color: $black;
    color: $label-primary-dark;

    &:disabled {
      background-color: unset;
    }
  }

  &[data-hierarchy='borderless'] {
    @media (hover) and (pointer: fine) {
      &:hover {
        background-color: $fill-disabled-light;
      }
    }

    color: $button-anchor-light;

    &:disabled {
      background-color: unset;
    }
  }

  &[data-hierarchy='borderless-gray'] {
    @media (hover) and (pointer: fine) {
      &:hover {
        background-color: unset;
        color: $button-anchor-light;
      }
    }

    color: $label-primary-light;

    &:disabled {
      background-color: unset;
    }
  }

  &[data-hierarchy='chip-filter'] {
    border: 0.0625rem solid $stroke;
    color: $label-primary-light;
  }

  &[data-hierarchy='bezeled-gray'],
  &[data-hierarchy='chip-choice'] {
    @media (hover) and (pointer: fine) {
      &:hover {
        background-color: $fill-primary-light;
      }
    }

    background-color: $fill-inactive-light;
    color: $label-primary-light;
  }

  &[data-hierarchy='bezeled'] {
    @media (hover) and (pointer: fine) {
      &:hover {
        background-color: $fill-primary-light;
      }
    }

    background-color: $fill-inactive-light;
    color: $brand;
  }

  &[data-hierarchy='filled'] {
    @media (hover) and (pointer: fine) {
      &:hover {
        background-color: mix($fill-base, $brand, 12%);
      }
    }

    background-color: $brand;
    color: $label-primary-dark;

    &[data-theme='dark'] {
      @media (hover) and (pointer: fine) {
        &:hover {
          background-color: mix($fill-base, $background-surface, 12%);
          color: $label-primary-light;
        }
      }

      background-color: $background-surface;
      color: $label-primary-light;
    }

    &[data-is-loading='true'] {
      background-color: $color-neutrals-03;
      color: $label-disabled-light;
    }
  }

  &[data-hierarchy='outline'] {
    @media (hover) and (pointer: fine) {
      &:hover:not(:disabled) {
        background-color: $fill-disabled-light;
        color: $label-primary-light;
      }

      &:disabled:hover {
        background-color: unset;
      }
    }

    border: 0.0625rem solid $fill-inactive-light;
    color: $label-primary-light;

    &:disabled {
      background-color: unset;
    }

    &[data-theme='dark'] {
      @media (hover) and (pointer: fine) {
        &:hover:not(:disabled) {
          color: $button-anchor-dark;
        }
      }

      color: $button-anchor-dark;
    }
  }

  &[data-hierarchy='text'] {
    @media (hover) and (pointer: fine) {
      &:hover:not(:disabled) {
        background-color: $fill-disabled-light;
        color: $label-primary-light;
      }

      &:disabled:hover {
        background-color: unset;
      }
    }

    width: fit-content;
    color: $label-primary-light;

    &:disabled {
      background-color: unset;
    }

    &[data-size='sm'] {
      gap: 0.25rem;

      &[data-has-padding='true'] {
        padding: 0.4375rem 0.375rem;

        &[data-has-icon='true'] {
          padding-right: 0.5rem;
          padding-left: 0.375rem;

          &[data-icon-position='right'] {
            padding-right: 0.375rem;
            padding-left: 0.5rem;
          }

          &[data-is-square='true'] {
            padding: 0.4375rem;
          }
        }
      }
    }

    &[data-size='md'] {
      gap: 0.375rem;

      &[data-has-padding='true'] {
        padding: 0.625rem 0.5rem;

        &[data-has-icon='true'] {
          padding-right: 1rem;
          padding-left: 0.75rem;

          &[data-icon-position='right'] {
            padding-right: 0.75rem;
            padding-left: 1rem;
          }

          &[data-is-square='true'] {
            padding: 0.625rem;
          }
        }
      }
    }

    &[data-size='lg'] {
      gap: 0.5rem;

      &[data-has-padding='true'] {
        padding: 0.75rem;

        &[data-has-icon='true'] {
          padding-right: 1rem;
          padding-left: 0.75rem;

          &[data-icon-position='right'] {
            padding-right: 0.75rem;
            padding-left: 1rem;
          }

          &[data-is-square='true'] {
            padding: 0.75rem;
          }
        }
      }
    }
  }

  &[data-hierarchy='success'] {
    @media (hover) and (pointer: fine) {
      &:hover {
        background-color: mix($fill-base, $green, 12%);
      }
    }

    background-color: $green;
    color: $system-white;
  }

  &[data-hierarchy='chip-choice'],
  &[data-hierarchy='chip-filter'] {
    &[data-selected='true'] {
      @media (hover) and (pointer: fine) {
        &:hover {
          background-color: $accent;
          color: $system-white;
        }
      }

      background-color: $accent;
      color: $system-white;

      &[data-theme='dark'] {
        @media (hover) and (pointer: fine) {
          &:hover {
            background-color: mix($fill-base, $background-surface, 12%);
            color: $label-primary-light;
          }
        }

        background-color: $background-surface;
        color: $label-primary-light;
      }
    }

    &:disabled {
      svg {
        color: $label-primary-light;
      }
    }
  }

  &[data-hierarchy='tab-choice'] {
    @media (hover) and (pointer: fine) {
      &:hover {
        background: $fill-primary-light;
        color: $label-primary-light;
      }
    }

    color: $label-inactive-light;

    &[data-selected='true'] {
      color: $label-primary-light;
    }

    &[data-theme='dark'] {
      color: $label-inactive-dark;

      &[data-selected='true'] {
        color: $white;
      }
    }
  }

  &[data-size='sm'] {
    gap: 0.25rem;

    &[data-has-padding='true'] {
      padding: 0.4375rem 0.75rem;
      border-radius: 6.25rem;

      &[data-has-icon='true'] {
        padding-right: 0.75rem;
        padding-left: 0.5rem;

        &[data-icon-position='right'] {
          padding-right: 0.5rem;
          padding-left: 0.75rem;
        }

        &[data-is-square='true'] {
          padding: 0.4375rem;
        }
      }
    }
  }

  &[data-size='md'] {
    gap: 0.38rem;

    &[data-has-padding='true'] {
      padding: 0.625rem 1rem;
      border-radius: 6.25rem;

      &[data-has-icon='true'] {
        padding-right: 1rem;
        padding-left: 0.75rem;

        &[data-icon-position='right'] {
          padding-right: 0.75rem;
          padding-left: 1rem;
        }

        &[data-is-square='true'] {
          padding: 0.625rem;
        }
      }
    }
  }

  &[data-size='lg'] {
    @include text-md;

    gap: 0.5rem;

    &[data-has-padding='true'] {
      padding: 0.75rem 1.5rem;
      border-radius: 6.25rem;

      &[data-has-icon='true'] {
        padding-right: 1.5rem;
        padding-left: 1rem;

        &[data-icon-position='right'] {
          padding-right: 1rem;
          padding-left: 1.5rem;
        }

        &[data-is-square='true'] {
          padding: 0.75rem;
        }
      }
    }
  }

  &:disabled:not([data-is-loading='true']) {
    background-color: $fill-disabled-light;
    color: $label-disabled-light;
  }

  &[data-has-background='false'] {
    @media (hover) and (pointer: fine) {
      &:hover,
      &:disabled:hover svg {
        background-color: transparent;
      }
    }

    background-color: transparent !important;

    &:disabled,
    &:disabled svg {
      background-color: transparent;
    }
  }

  &[data-has-padding='false'] {
    justify-content: flex-start;
    padding: 0;
  }

  &[data-is-loading='true'] {
    @media (hover) and (pointer: fine) {
      &:hover {
        cursor: default;
      }
    }

    &[data-retains-width-on-load='true'] {
      .loader {
        opacity: 1;
      }

      .label,
      > svg:not(.loader) {
        appearance: none;
        opacity: 0;
        user-select: none;
      }
    }
  }

  .label:empty {
    display: none;
  }

  &[data-retains-width-on-load='true'] {
    .loader,
    .label,
    svg:not(.loader) {
      transition: opacity 50ms ease-in-out;
    }

    .loader {
      position: absolute;
      animation: rotate 1s infinite linear;
      opacity: 0;
    }
  }

  .loader-container {
    display: flex;
    height: 1.4rem;
    align-items: center;
  }

  .loader-dots {
    display: block;
    width: 0.5rem;
    height: 0.5rem;
    border-radius: 50%;
    animation: loaderDots 1s infinite linear alternate;
  }
}

@keyframes rotate {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes loaderDots {
  0% {
    background: $color-shade-01;
    box-shadow:
      0.875rem 0 $color-shade-01,
      -0.875rem 0 $color-neutrals-02;
  }

  33% {
    background: $color-neutrals-02;
    box-shadow:
      0.875rem 0 $color-shade-01,
      -0.875rem 0 $color-neutrals-02;
  }

  66% {
    background: $color-neutrals-02;
    box-shadow:
      0.875rem 0 $color-neutrals-02,
      -0.875rem 0 $color-shade-01;
  }

  100% {
    background: $color-shade-01;
    box-shadow:
      0.875rem 0 $color-neutrals-02,
      -0.875rem 0 $color-shade-01;
  }
}
