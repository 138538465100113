@import "styles/variables.scss"; @import "styles/colours.scss"; @import "styles/mixins.scss"; @import "styles/typography.scss";
.group {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  gap: 0.5rem;

  &[data-has-scroll='true'] {
    flex-wrap: nowrap;
    overflow-x: scroll;
    scroll-behavior: smooth;
    scrollbar-width: none;
    white-space: nowrap;
  }

  &::-webkit-scrollbar {
    display: none;
  }
}
