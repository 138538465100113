@import "styles/variables.scss"; @import "styles/colours.scss"; @import "styles/mixins.scss"; @import "styles/typography.scss";
.main {
  display: grid;
  width: 100%;
  max-width: 32rem;
  gap: $spacing-sm;
}

.wrapper {
  display: grid;
  gap: $spacing-sm;
}

.form {
  display: flex;
  width: 100%;
  align-items: center;
  padding: $spacing-xs;
  border: 0.125rem solid $background-backdrop;
  border-radius: 100px;
  background: $background-surface;
  gap: $spacing-xxs;
}

.field {
  display: grid;
  padding: 0 0.75rem;

  @include screen-size('medium') {
    padding: 0 1.25rem;
  }

  label {
    @include text-sm;
    @include text-bold;

    margin: 0;
    color: $label-inactive-light;
  }
}

.separator {
  position: relative;
  display: block;
  width: 0.0625rem;
  height: $spacing-lg;
  background-color: $stroke;
  content: '';
}

.pills {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: $spacing-xs;

  @include screen-size('medium') {
    justify-content: flex-start;
  }

  &__title {
    @include text-sm;
    @include text-normal;

    color: $label-primary-dark;

    @include screen-size('medium') {
      @include text-md;
    }
  }

  &__container {
    @include text-sm-link;

    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: 0;
    margin: 0;
    color: $label-primary-dark;
    gap: 0.5rem;
    list-style: none;
  }
}
