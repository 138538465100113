// 2022 Style Guide
$black: #000;
$white: #fff;
$black-filter: brightness(0) saturate(100%) invert(0%) sepia(3%) saturate(2959%) hue-rotate(222deg)
  brightness(91%) contrast(102%);
$white-filter: brightness(0) saturate(100%) invert(94%) sepia(0%) saturate(7500%) hue-rotate(76deg)
  brightness(105%) contrast(105%);
$grey-50: #fafafa;
$grey-100: #f5f5f5;
$grey-200: #ebeaeb;
$grey-300: #d6d5d7;
$grey-400: #c2c1c3;
$grey-500: #aaa8ac;
$grey-600: #99979b;
$grey-700: #858287;
$grey-800: #807f83;
$grey-900: #07081a;
$grey-400-filter: brightness(0) saturate(100%) invert(94%) sepia(8%) saturate(40%)
  hue-rotate(227deg) brightness(81%) contrast(91%);
$grey-500-filter: brightness(0) saturate(100%) invert(80%) sepia(8%) saturate(92%)
  hue-rotate(227deg) brightness(84%) contrast(88%);
$grey-900-filter: brightness(0) saturate(100%) invert(6%) sepia(8%) saturate(4518%)
  hue-rotate(199deg) brightness(90%) contrast(104%);
$primary-50: #fff3d6;
$primary-100: #ffe7ad;
$primary-200: #ffda85;
$primary-300: #ffce5c;
$primary-400: #ffc233;
$primary-500: #f8ae00;
$primary-600: #e09d00;
$primary-700: #b88100;
$primary-800: #8f6400;
$primary-900: #664700;
$secondary-50: #fef6f7;
$secondary-100: #fdedef;
$secondary-200: #f8c9d0;
$secondary-300: #f4a4b0;
$secondary-400: #ef8091;
$secondary-500: #ea5168;
$secondary-600: #e63752;
$secondary-700: #da1b38;
$secondary-800: #b6162e;
$secondary-900: #911225;
$secondary-700-filter: brightness(0) saturate(100%) invert(22%) sepia(41%) saturate(6098%)
  hue-rotate(339deg) brightness(86%) contrast(99%);
$success-50: #e5f0e5;
$success-100: #d8e9d8;
$success-200: #bedabe;
$success-300: #a4cba4;
$success-400: #8abc8a;
$success-500: #67a867;
$success-600: #599b59;
$success-700: #4a824a;
$success-800: #3c683c;
$success-900: #2d4e2d;
$facebook: #3b5998;
$twitter: #00aced;
$reddit: #5f99cf;
$linkedin: #007fb1;
$email: #7f7f7f;
$whatsapp: #2cb742;
$moneySuperMarketPurple: #590f85;
$motorwayYellow: #fded42;
$motorwayBlue: #0460cc;
$instagram: #c32aa3;
$youtube: #f00;
$linkedin: #0a66c2;
$tiktok: #010101;
$twitter-x: $black;
$facebook-filter: brightness(0) saturate(100%) invert(33%) sepia(28%) saturate(1158%)
  hue-rotate(182deg) brightness(93%) contrast(88%);
$instagram-filter: brightness(0) saturate(100%) invert(26%) sepia(32%) saturate(5290%)
  hue-rotate(291deg) brightness(90%) contrast(93%);
$youtube-filter: brightness(0) saturate(100%) invert(21%) sepia(72%) saturate(6546%)
  hue-rotate(355deg) brightness(95%) contrast(121%);
$linkedin-filter: brightness(0) saturate(100%) invert(20%) sepia(99%) saturate(4421%)
  hue-rotate(200deg) brightness(94%) contrast(92%);
$tiktok-filter: brightness(0) saturate(100%) invert(0%) sepia(6%) saturate(7500%) hue-rotate(153deg)
  brightness(106%) contrast(98%);
$x-filter: brightness(0);
$danger: #dc3545;
$info: #daedef;
$primary-pink: #eb4868;
$primary-yellow: #f4be54;
$secondary-red: #ea2027;
$secondary-orange: #e1833e;
$secondary-maroon: #b53471;
$secondary-violet: #3a0642;
$tertiary-purple: #5f27cd;
$tertiary-green: #86cb92;
$tertiary-blue: #0abde3;
$primary-pink-filter: brightness(0) saturate(100%) invert(42%) sepia(92%) saturate(3162%)
  hue-rotate(324deg) brightness(98%) contrast(88%);
$secondary-violet-filter: brightness(0) saturate(100%) invert(5%) sepia(48%) saturate(7401%)
  hue-rotate(287deg) brightness(94%) contrast(101%);

@mixin pink-to-maroon-background {
  background: $primary-pink;
  background: linear-gradient(90deg, $primary-pink 0%, $secondary-maroon 100%);
}

@mixin yellow-to-pink-to-maroon-to-purple-to-maroon-background {
  background: $primary-yellow;
  background: linear-gradient(
      136.48deg,
      $primary-yellow 24.59%,
      $primary-pink 62.3%,
      $secondary-maroon 100%
    ),
    linear-gradient(102.64deg, $tertiary-purple 0%, $secondary-maroon 89.01%);
}

@mixin maroon-to-red-alt-background {
  background: $secondary-maroon;
  background: linear-gradient(270deg, $secondary-maroon 0%, $secondary-red 100%);
}

@mixin text-purple-gradient {
  background: linear-gradient(270deg, $secondary-maroon 0%, $secondary-red 100%),
    linear-gradient(269.97deg, $secondary-red 0.02%, #efb64f 99.98%),
    linear-gradient(90deg, $primary-500 0%, $secondary-500 100%);
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

@mixin text-gradient {
  background: linear-gradient(90deg, $primary-500 0%, $secondary-500 100%);
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

$system-black: rgb(7 8 26);
$system-grey: rgb(142 142 147);
$system-grey-2: rgb(174 174 178);
$system-grey-3: rgb(199 199 204);
$system-grey-4: rgb(209 209 214);
$system-grey-5: rgb(229 229 234);
$system-grey-6: rgb(242 242 247);
$system-white: rgb(255 255 255);
$system-off-white: rgb(235 235 245);
$pink: rgb(255 45 85);
$yellow: rgb(255 204 0);
$maroon: rgb(181 52 113);
$orange: rgb(241 90 36);
$purple: rgb(58 6 66);
$green: rgb(72 160 122);
$red: rgb(255 59 48);
$blue: rgb(10 189 227);
$lilac: rgb(95 39 205);
$pale-yellow: rgb(255 240 217);
$primary-dark: $system-black;
$secondary-dark: rgba($system-black, 0.6);
$tertiary-dark: rgba($system-black, 0.3);
$quaternary-dark: rgba($system-black, 0.16);
$primary-light: $system-white;
$secondary-light: rgba($system-off-white, 0.6);
$tertiary-light: rgba($system-off-white, 0.3);
$quaternary-light: rgba($system-off-white, 0.16);
$label-text: $grey-900;
$label-primary-light: $primary-dark;
$label-supporting-light: $secondary-dark;
$label-inactive-light: $tertiary-dark;
$label-disabled-light: $quaternary-dark;
$label-primary-dark: $primary-light;
$label-supporting-dark: $secondary-light;
$label-inactive-dark: $tertiary-light;
$label-disabled-dark: $quaternary-light;
$fill-base: rgb(120 120 128);
$fill-primary-light: rgba($fill-base, 0.2);
$fill-supporting-light: rgba($fill-base, 0.16);
$fill-inactive-light: rgba($fill-base, 0.12);
$fill-disabled-light: rgba($fill-base, 0.08);
$fill-primary-dark: rgba($fill-base, 0.36);
$fill-supporting-dark: rgba($fill-base, 0.32);
$fill-inactive-dark: rgba($fill-base, 0.24);
$fill-disabled-dark: rgba($fill-base, 0.18);
$brand: $pink;
$accent: $maroon;
$feature: $purple;
$stroke: $system-grey-5;
$error: $red;
$background-backdrop: $system-grey-6;
$background-surface: $system-white;
$background-overlay: rgb(0 0 0 / 40%);
$background-overlay-image: linear-gradient(225deg, rgb(0 0 0 / 0%) 0%, rgb(0 0 0 / 60%) 100%);

// Button
$button-label-light: $label-primary-light;
$button-label-dark: $label-primary-dark;
$button-anchor-light: $brand;
$button-anchor-dark: $label-primary-dark;
$background-success-dark: $green;
$background-success-light: $green;
$background-button-filled-light: $brand;
$background-button-filled-dark: $background-surface;
$button-background-success: #00a377;

// Seasonal '23
$seasonal-purple: #1f0318;

// LUI
$stroke-border: #ebebeb;
$color-shade-01: #fff;
$color-shade-02: #222;
$color-shade-025: rgba($color-shade-02, 0.05);
$color-shade-0230: rgba($color-shade-02, 0.3);
$text-title: $color-shade-02;
$text-body: #5d5d5d;
$stroke-border: #ebebeb;
$color-primary-01: #ff2d55;
$color-neutrals-01: #f7f7f7;
$color-neutrals-02: #ebebeb;
$color-neutrals-03: #ddd;
$color-neutrals-04: #d3d3d3;
$color-neutrals-05: #c2c2c2;
$color-neutrals-06: #b0b0b0;
$color-neutrals-07: #717171;
$color-neutrals-08: #5d5d5d;
$color-accents-01: #ffd5dd;
$color-accents-02: #ffd700;
$color-accents-03: #40e0d0;
$color-accents-04: #008a05;
$color-accents-05: #ff7f32;
$color-error-01: #fef8f6;
$color-error-02: #c13515;
$button-primary-background: $color-primary-01;
$disabled-bg: #ddd;
